import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql} from "gatsby"

export default function Head({ title, description, robots }) {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <Helmet>
            <html lang="de" />
            <meta charSet="utf-8" />
            <title>{`${title} | ${data.site.siteMetadata.title}`}</title>
            <meta name="description" content={`${description}`} />
            <meta name="robots" content={`${robots}`} />
            <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
        </Helmet>
    )
}